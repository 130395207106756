<template>
	<div class="cs-block cs-jobs" :class="`${styleClass}${hasBg}`">
		<div class="cs-block-base">
			<div class="container">
				<CSJobs :data="data" />
			</div>
		</div>
    </div>
</template>

<script>
	import {computed} from '../libs/common-fn';

	export default {
		components: {
			CSJobs: () => import("../components/CSJobs.vue") 
		},
		name: "Job",
		data() {
			return {}
		},
		props: {
			data: {
				type: Object,
				default: () => { return {} },
			},
		},
		computed: {
			...computed('Job'),
			hasBg() {
                if (this.data.style == 'color') return ' has-background';

                return '';
            }
		}
	}
</script>

<style lang="scss" scoped>
	@import "../styles/main.scss";

	.cs-jobs {
		&.cs-style- {
			&color {
				::v-deep {
					@include cs-block-color;
					.cs-sub-title{
						color:$sub_title_color;
					}
					*, ::v-deep *{
						color:white;
					}
				}
			}

			&light {
				::v-deep {
					@include cs-block-light;
					.cs-sub-title{
						color: $sub_title_color_light;
					}
				}
			}

			&dark {
				::v-deep {
					@include cs-block-dark;
					.cs-sub-title{
						color: $sub_title_color_dark;
					}
				}
			}
		}
	}
</style>
